import axios from './sticarAxios';

export default {
  fetchLiveStart(device_serial) {
    return axios
      .get(`/api/cloud/live/start/${device_serial}`, { params: { } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },

  fetchLiveContinue(device_serial) {
    return axios
      .get(`/api/cloud/live/continue/${device_serial}`, { params: { } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },

  fetchCurrentDeviceList() {
    return axios
      .get(`/api/cloud/device/current/list`, { params: { } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  fetchVideoList(from, to, type, device_serial) {
    let timezoneOffset = new Date().getTimezoneOffset();
    return axios
      .get(`/api/cloud/video/list`, { params: { from, to, type, device_serial, timezoneOffset} })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  fetchVideo(id) {
    let timezoneOffset = new Date().getTimezoneOffset();
    return axios
      .get(`/api/cloud/video/detail/${id}`, { params: { timezoneOffset} })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  fetchGeofenceDetail(id) {
    const url = `/api/cloud/geofence/detail/${id}`
    return axios
      .get(url)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  fetchDeviceTrackEventDetail(sn) {
    const url = `/api/cloud/track/event/detail/${sn}`
    return axios
      .get(url)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },

  fetchLastPosition(device_serial) {
    return axios
    .get(`/api/cloud/video/detail/last/position/${device_serial}`)
    .then((res) => res.data)
    .catch((e) => {
      throw e;
    });
  },
  
  // fetchTrackEvent({ device_serial, trip_id, node_id, incident_time, content_type, content_id, daylight, event_data, notification }) {
  //   return axios
  //     .post(`/api/dashcam/track/file/download`, {
  //       device_serial: device_serial,
  //       trip_id: trip_id,
  //       node_id: node_id,
  //       incident_time: incident_time,
  //       content_type: content_type,
  //       content_id: content_id,
  //       daylight: daylight,
  //       event_data: event_data,
  //       notification: notification,
  //     })
  //     .then((res) => res.data)
  //     .catch((e) => {
  //       throw e;
  //     });
  // },

  deleteVideo(id) {
    return axios
      .get(`/api/cloud/video/remove/${id}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
};
