async function fetchLastPosition(track_list, map, lastPositionMarker, svgCode) {
    if(track_list && track_list.length > 0) {
    let newPositions = [];
        track_list.map((item) => {
        if(item.latitude != 0 || item.longitude != 0)
            newPositions.push(new google.maps.LatLng(item.latitude, item.longitude));

        if (newPositions.length > 0) {
            const lastPosition = newPositions[newPositions.length - 1];

            if (lastPositionMarker) {
                const currentPosition = lastPositionMarker.getPosition();
                animateMarker(lastPositionMarker, currentPosition, lastPosition, 500, map);
            } else {
                lastPositionMarker = new google.maps.Marker({
                    position: lastPosition,
                    map: map,
                    icon: {
                        url: `data:image/svg+xml;utf8,${svgCode}`,
                        scaledSize: new google.maps.Size(40, 40),
                        anchor: new google.maps.Point(20, 20),
                    },
                });
                map.setCenter(lastPosition);
            }
        }
    });
    }

    return lastPositionMarker;
}

function animateMarker(marker, startLatLng, endLatLng, duration, map) {
    const startTime = new Date().getTime();

    const interpolate = (from, to, currentTime) => {
        return from + (to - from) * currentTime;
    };

    const animate = () => {
        const currentTime = new Date().getTime() - startTime;
        const progress = currentTime / duration;

        if (progress < 1) {
            const lat = interpolate(startLatLng.lat(), endLatLng.lat(), progress);
            const lng = interpolate(startLatLng.lng(), endLatLng.lng(), progress);
            const newPosition = new google.maps.LatLng(lat, lng);
            marker.setPosition(newPosition);

            if (!map.getBounds().contains(newPosition)) {
                map.setCenter(newPosition);
            }

            requestAnimationFrame(animate);
        } else {
            marker.setPosition(endLatLng);
            if (!map.getBounds().contains(endLatLng)) {
                map.setCenter(endLatLng);
            }
        }
    };

    animate();
}

export default fetchLastPosition;