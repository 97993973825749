<template>
  <component :is="layout">
    <slot></slot>
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DesktopLayout from './desktop.vue';
import MobileLayout from './mobile.vue';
import MapLayout from './mapLayout.vue';
import MobileDetect from 'mobile-detect';

export default {
  components: {
    DesktopLayout,
    MobileLayout,
    MapLayout,
  },

  setup() {
    const md = new MobileDetect(window.navigator.userAgent);
    const route = useRoute();
    const layout = computed(() => {
      if (route.meta.layout == 'map') return 'MapLayout';
      else if (md.mobile()) return 'MobileLayout';
      else return 'DesktopLayout';
    });
    return {
      layout,
    };
  },
};
</script>
