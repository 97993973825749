import axios from './sticarAxios';

export default {
  fetchCloudStorage(member_no) {
    return axios
      .get(`/api/my`, { params: { member_no } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
};
