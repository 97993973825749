<template>
  <div class="map-container">
    <google-map
      :crashCar="[crashCar]" :screen="screen" :path="path" :style="{ position: 'absolute' }" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import GoogleMap from '../components/GoogleMap.vue';
import { isEmpty } from 'lodash';

export default {
  components: {
    GoogleMap,
  },
  data() {
    return {
      path: [],
      crashCar: {},
      pin: '',
      screen: '',
    };
  },
  created() {
    window.mapView = this;
  },
  mounted() {
    const route = useRoute();
    if (isEmpty(route.query)) return;

    const p = route.query.p;
    const screen = route.query?.screen;

    const pArray = Array.isArray(p) ? p : [p];
    const path = pArray.map((item) => ({ lat: parseFloat(item.split(',')[0]), lng: parseFloat(item.split(',')[1]) }));

    if(screen === 'crash') {
      this.crashCar = path[path.length - 1];
    }
    
    this.update(path, screen);
  },
  methods: {
    update(path, screen) {
      if (!Array.isArray(path)) {
        console.log('path is not array');
        return;
      }
      this.path = path;
      this.screen = screen;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
