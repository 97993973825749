<template>
  <div id="map" :style="mapStyle"></div>
</template>

<script>
import productService from '../services/product';
import fetchLastPosition from '../services/fetchLastPosition'; 

class CustomBubbleOverlay extends google.maps.OverlayView {
  constructor(position, text, map) {
    super();
    this.position = position;
    this.text = text;
    this.map = map;
    this.div = null;
    this.setMap(map);
  }

  onAdd() {
    var div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.transform = 'translate(-50%, -100%)';
    div.style.backgroundColor = '#FF0000';
    div.style.width = '60px';
    div.style.padding = '5px 10px';
    div.style.border = '2px solid #FF0000';
    div.style.borderRadius = '4px';
    div.style.color = 'white';
    div.style.textAlign = 'center';
    div.style.fontSize = '16px';
    div.style.boxShadow = '0 2px 6px rgba(0,0,0,0.3)';
    div.style.zIndex = '100';
    div.innerHTML = this.text;

    var arrow = document.createElement('div');
    arrow.style.position = 'absolute';
    arrow.style.bottom = '-10px';
    arrow.style.left = '50%';
    arrow.style.width = '0';
    arrow.style.height = '0';
    arrow.style.borderLeft = '10px solid transparent';
    arrow.style.borderRight = '10px solid transparent';
    arrow.style.borderTop = '10px solid #FF0000';
    arrow.style.transform = 'translateX(-50%)';
    arrow.style.zIndex = '100'; // 화살표에도 z-index 설정

    div.appendChild(arrow);
    this.div = div;

    var panes = this.getPanes();
    panes.overlayLayer.appendChild(div);
  }

  draw() {
    var overlayProjection = this.getProjection();
    var position = overlayProjection.fromLatLngToDivPixel(this.position);

    var div = this.div;
    div.style.left = position.x + 'px';
    div.style.top = position.y + 'px';
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }
}


export default {
  name: 'GoogleMap',
  props: {
    id: {
      type: String,
      default: 0
    },
    screen: {
      type: String,
      default: ''
    },
    path: {
      type: Object,
      default() {
        return () => [];
      },
    },
    carPosition: {
      type: Object,
      default() {
        return {};
      },
    },
    style: {
      type: Object,
      default() {
        return {};
      },
    },
    crashCar: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  data() {
    return {
      svg_code: encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><g fill="none" fill-rule="evenodd"><circle cx="20" cy="20" r="20" fill="#F8DD4A"/><path fill="#FFF" fill-rule="nonzero" d="M14 26v1c0 .283-.096.52-.287.712A.968.968 0 0 1 13 28h-1a.968.968 0 0 1-.713-.288A.968.968 0 0 1 11 27v-8l2.1-6c.1-.3.28-.542.537-.725.259-.183.546-.275.863-.275h11c.317 0 .604.092.863.275.258.183.437.425.537.725l2.1 6v8c0 .283-.096.52-.288.712A.968.968 0 0 1 28 28h-1a.968.968 0 0 1-.712-.288A.968.968 0 0 1 26 27v-1H14Zm-.2-9h12.4l-1.05-3h-10.3l-1.05 3Zm-.763 2v5-5Zm2.463 4c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 15.5 20c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 14 21.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm9 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 24.5 20c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 23 21.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM13 24h14v-5H13v5Z"/></g></svg>'),
      crash_svg_code: encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><g fill="none" fill-rule="evenodd"><circle cx="20" cy="20" r="20" fill="#F8DD4A"/><path fill="#FFF" fill-rule="nonzero" d="M13.037 24v-5 5ZM11 19l2.1-6c.1-.3.28-.542.537-.725.259-.183.546-.275.863-.275h4.575a6.732 6.732 0 0 0 0 2H14.85l-1.05 3h6.475c.283.4.6.77.95 1.113.35.341.742.637 1.175.887H13v5h14v-4.075a7.014 7.014 0 0 0 2-.6V27c0 .283-.096.52-.288.712A.968.968 0 0 1 28 28h-1a.968.968 0 0 1-.712-.288A.968.968 0 0 1 26 27v-1H14v1c0 .283-.096.52-.287.712A.968.968 0 0 1 13 28h-1a.968.968 0 0 1-.713-.288A.968.968 0 0 1 11 27v-8Zm13.5 4c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 24.5 20c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 23 21.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm-9 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 15.5 20c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 14 21.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM26 18c-1.383 0-2.563-.488-3.538-1.462C21.488 15.562 21 14.383 21 13c0-1.367.483-2.542 1.45-3.525C23.417 8.492 24.6 8 26 8c1.383 0 2.563.488 3.538 1.463C30.512 10.438 31 11.617 31 13s-.488 2.563-1.462 3.538C28.562 17.512 27.383 18 26 18Zm-.5-4h1v-4h-1v4Zm.5 2a.48.48 0 0 0 .35-.15.48.48 0 0 0 0-.7.48.48 0 0 0-.7 0 .48.48 0 0 0 0 .7c.1.1.217.15.35.15Z"/></g></svg>'),
      map: null,
      count: 0,
    };
  },
  watch: {
    path: {
      immediate: false,
      deep: true,
      handler: function (val) {
        if(this.screen == 'crash')
          return
        this.updatePath(this.map, val);
      },
    },
    crashCar: {
      immediate: false,
      deep: true,
      handler: function (val) {
        console.log('val => ', val);
        return this.crashCarPosition(this.map, val[0]);
      },
    },
    carPosition: {
      immediate: false,
      deep: true,
      handler: function (val) {
        this.updateCarPosition(this.map, val);
      },
    },

  },
  computed: {
    mapStyle() {
      return {
        height: '100%',
        width: '100%',
        ...this.style,
      };
    },
  },
  mounted() {
    const options = { 
      zoom: 15, 
      zoomControl: true, 
      center: new google.maps.LatLng(40.684035,-74.0942416),
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    };
    this.map = new google.maps.Map(document.getElementById('map'), options);
    window.addEventListener('currentCar', this.currentCar, false);
  },
  methods: {
    async updateCarPosition(map, position) {
      if (!map) return;
      if (!position) return;
      
      const last_obj = {
        latitude: position.lat,
        longitude: position.lng
      };
      this.lastPositionMarker = await fetchLastPosition([last_obj], this.map, this.lastPositionMarker, this.svg_code);
    },

    async currentCar(event) {
      const lat = event.detail.lat;
      const lng = event.detail.lng;
      
      const last_obj = {
        latitude: lat,
        longitude: lng
      };
      this.lastPositionMarker = await fetchLastPosition([last_obj], this.map, this.lastPositionMarker, this.svg_code);
    },

    createCustomBubbleOverlay(map, position, text) {
      return new CustomBubbleOverlay(position, text, map);
    },

    async crashCarPosition(map, position) {
      if (!map) return;
      if (!position) return;

      if(position && position.lat && position.lng) {
        position.lat = parseFloat(position.lat);
        position.lng = parseFloat(position.lng);
      }

      const crashCarMarker = new google.maps.Marker({
          position: position,
          map: map,
          icon: {
              url: `data:image/svg+xml;utf8,${this.crash_svg_code}`,
              scaledSize: new google.maps.Size(40, 40),
              anchor: new google.maps.Point(20, 20),
          },
      });
      this.crashCarMarker = crashCarMarker;
      map.setCenter(position);
    },

    async updatePath (map, path = []) {
      if (!map) return;
      if (path.length === 0) return;

      const google = window.google;
      const positions = [];
      path.map((item) => {
        if(item.lat != 0 && item.lng != 0)
          positions.push(new google.maps.LatLng(item.lat, item.lng));
      });

      //커스텀 오버레이 추가 
      if (positions.length > 0) {
        this.createCustomBubbleOverlay(map, positions[0], 'Start');
        if (positions.length > 1) {
          this.createCustomBubbleOverlay(map, positions[positions.length - 1], 'End');
        }

        // const obj = path[path.length -1];
        // const last_obj = {
        //   latitude: obj.lat,
        //   longitude: obj.lng
        // }
        // this.lastPositionMarker = await fetchLastPosition([last_obj], this.map, this.lastPositionMarker, this.svg_code);
      }
      

      const polyline = new google.maps.Polyline({
        path: positions,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons: [{ icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW } }],
      });

      polyline.setMap(map);
      map.setCenter(positions.at(-1));
    },
  },

  beforeDestroy() {
    window.removeEventListener('currentCar', this.currentCar);
  }
};
</script>
