<template>
  <div class="main-container">
    <div class="top-contents">
      <div class="rect">
        <h2>Live View</h2>
        <div style="margin-bottom: 52px; margin-top: 28px">
          <img src="../assets/img/live.png" alt="live" />
        </div>
        <a-button :size="'large'" :style="{ width: '328px', background: '#F8DD4A', color: 'black' }" @click="handleClickLiveView">
          Go To Live View
        </a-button>
      </div>
      <div class="rect">
        <h2>Cloud Storage</h2>
        <div :style="{ width: '100%' }">
          <div :style="{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }">
            <label>Storage Used</label><span>{{ storage.disk }} MB of 500 MB used ({{ storage.usage }}%)</span>
          </div>
          <a-progress :percent="storage.usage" :strokeColor="storage.usage > 100 ? '#ff0000' : '#F8DD4A'" :show-info="false" />
        </div>
        <a-divider style="margin: 36px 0px; height: 2px; background-color: #495057" />
        <div :style="{ width: '100%' }">
          <div :style="{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }">
            <label>Watch Remote Live View</label>
            <div>
              <span :style="{ color: '#F8DD4A' }">{{ storage.remote }}</span
              ><span> times of 300 times per month</span>
            </div>
          </div>
          <div :style="{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }">
            <label>Download Cloud Video</label>
            <div>
              <span :style="{ color: '#F8DD4A' }">{{ storage.download }}</span
              ><span> times of 100 times per month</span>
            </div>
          </div>
          <div :style="{ display: 'flex', justifyContent: 'space-between' }">
            <label>Upload Parking/Continuous<br />Remote Live View</label>
            <div>
              <span :style="{ color: '#F8DD4A' }">{{ storage.upload }}</span
              ><span> minutes of 300 minutes per month</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rect" :style="{ marginTop: '32px', width: '100%', height: '100%', marginBottom: '32px' }">
      <h2>Cloud Impact Video</h2>
      <div :style="{ display: 'flex', gap: '8px', flexWrap: 'wrap' }">
        <a-button :type="periodType === 'today' ? 'primary' : 'default'" @click="handleClickPeriod('today')">Today</a-button>
        <a-button :type="periodType === 'week' ? 'primary' : 'default'" @click="handleClickPeriod('week')">Week</a-button>
        <a-button :type="periodType === 'month' ? 'primary' : 'default'" @click="handleClickPeriod('month')">Month</a-button>
        <a-button :type="periodType === 'custom' ? 'primary' : 'default'" @click="handleClickCustomPeriod()">Custom Date</a-button>
        <a-range-picker :disabled="disableCustomPeriod" :style="{ border: '1px solid #F8DD4A'}" @change="handleChangeCustomPeriod" />
      </div>
      <div class="date-navigation" :style="{ marginTop: '28px' }">
        <a-button shape="circle" :style="{ background: 'transparent', color: 'white', border: 'transparent' }" @click="handleClickMove('prev')">
          <template #icon><left-outlined /></template
        ></a-button>
        <span>{{ caption }}</span>
        <a-button shape="circle" :style="{ background: 'transparent', color: 'white', border: 'transparent' }" @click="handleClickMove('next')">
          <template #icon><right-outlined /></template
        ></a-button>
      </div>
      <div class="video-list">
        <div style="margin-bottom: 24px; width: 100%;">
          <a-select v-model:value="selectedDeviceSerial" style="width: 100%; border-radius: 7px; background: #000000">
            <a-select-option value="">All Devices</a-select-option>
            <a-select-option v-for="device of deviceList" :key="device.device_serial" :value="device.device_serial">
              <b>{{ device.device_serial }}</b>
            </a-select-option>
          </a-select>
        </div>
        <div v-if="filteredVideoList.length === 0" :style="{ display: 'flex', justifyContent: 'center', height: '80px' }">
          <span>No search results found.</span>
        </div>
        <div v-else :style="{ width: '100%' }">
          <ul>
            <li v-for="video of filteredVideoList" :key="video._id">
              <div class="video-summary">
                <div style="position: relative; cursor: pointer" @click="handleClickPlay(video._id)">
                  <img :src="video.preview" loading="lazy" class="preview" />
                  <div style="position: absolute; bottom: 2px; left: 4px">
                    <play-circle-filled
                      style="font-size: 20px; color: black; background-color: white; border-radius: 32px; box-shadow: 0 0 0 4px #000 inset" />
                  </div>
                </div>
                <div :style="{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
                  <div :style="{ display: 'flex', flexDirection: 'column'}">
                    <span style="font-size: 14px; line-height: 20px;">
                      {{ video.text1 }}
                      <span class="video_tag" :class="video.tag">
                        <span>
                          {{ video.tag }}
                        </span>
                      </span>
                    </span>
                    <span style="font-size: 14px">{{ video.text2 }}</span>
                    <span style="font-size: 12px; color: #bbb">{{ video.device_serial }} </span>
                  </div>
                  <a-button shape="circle" ghost :style="{ border: 'transparent' }" @click="handleClickDelete(video._id)">
                    <template #icon><delete-outlined :style="{ fontSize: '24px' }" /></template
                  ></a-button>
                </div>
              </div>
            </li>
          </ul>
          <a-button
            v-if="more" 
            :block="true"
            ghost
            :style="{
              borderRadius: '8px',
              background: '#495057',
              border: 'transparent',
            }"
            >More</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LeftOutlined, RightOutlined, DeleteOutlined, PlayCircleFilled } from '@ant-design/icons-vue';
import productService from '../services/product';
import memberService from '../services/member';
import moment from 'moment';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';

export default {
  components: { LeftOutlined, RightOutlined, DeleteOutlined, PlayCircleFilled },
  data() {
    return {
      storage: { disk: '0', usage: 0, download: 0, remote: 0, upload: 0 },
      disableCustomPeriod: true,
      periodType: 'month',
      from: '',
      to: '',
      videoList: [],
      selectedDeviceSerial: '',
      deviceList: [],
      prev: {},
      next: {},
      caption: '',
      currentPage: 0,
      more:true,
    };
  },
  computed: {
    filteredVideoList() {
      return this.selectedDeviceSerial ? this.videoList.filter((video) => video.device_serial === this.selectedDeviceSerial) : this.videoList;
    },
  },
  mounted() {
    const user = this.$store.state.user;
    this.fetchCloudStorage(user.member_no);
    this.fetchVideoList(this.from, this.to);
  },
  methods: {
    async fetchCloudStorage(memberNo) {
      const res = await memberService.fetchCloudStorage(memberNo);
      this.storage.disk = res.usage > 100 ? parseInt(res.storage_disk) : res.storage_disk;
      this.storage.usage = res.usage;
      this.storage.download = res.download_count_per_month;
      this.storage.remote = res.live_view_count_per_month;
      this.storage.upload = res.upload_duratopn_per_month;
    },

    async fetchVideoList(from, to, type) {
      const res = await productService.fetchVideoList(from, to, type);
      res.list.map(item => {
        if(item.content_id.includes('_f'))
          item.tag = 'Front'
        else 
          item.tag = 'Rear'
      })
      this.deviceList = res.device_list;
      this.videoList = res.list || [];
      this.more = res.more
      this.prev = res.prev;
      this.next = res.next;
      this.caption = res.caption_text;
    },

    handleClickLiveView() {
      this.$router.push('/device/select');
    },

    handleClickPlay(id) {
      this.$router.push(`/replay/${id}`);
    },

    handleClickPeriod(type) {
      let fromDate = '';
      let toDate = '';

      this.disableCustomPeriod = true;
      this.periodType = type;
      this.from = fromDate;
      this.to = toDate;
      this.fetchVideoList(fromDate, toDate, type);
    },

    handleClickMove(value) {
      if (value == 'prev') 
        this.fetchVideoList(this.prev.from, this.prev.to, this.periodType, this.selectedDeviceSerial);
      else 
        this.fetchVideoList(this.next.from, this.next.to, this.periodType, this.selectedDeviceSerial);
    },

    handleClickCustomPeriod() {
      this.disableCustomPeriod = false;
      this.periodType = 'custom';
    },

    parseStartOfDay(str) {
      str = str.replace(/-/g, '')
      if(!/^(\d){8}$/.test(str)) return "invalid date";
      var y = str.substr(0,4),
          m = str.substr(4,2),
          d = str.substr(6,2);
      return new Date(y,parseInt(m)-1,d).toISOString();
    },
    parseEndOfDay(str) {
      str = str.replace(/-/g, '')
      if(!/^(\d){8}$/.test(str)) return "invalid date";
      var y = str.substr(0,4),
          m = str.substr(4,2),
          d = str.substr(6,2);
      return new Date(y,parseInt(m)-1,d, 23,59, 59).toISOString();
    },

    handleChangeCustomPeriod(_, dateString) {
      if (!dateString) return;

      const [from, to] = dateString;      
      this.from = from
      this.to = to

      this.fetchVideoList(this.parseStartOfDay(from), this.parseEndOfDay(to), 'custom', this.selectedDeviceSerial);
    },
    async handleClickDelete(id) {
      Modal.confirm({
        title: 'Do you want to delete these items?',
        icon: createVNode(ExclamationCircleOutlined),
        content: '',
        onOk: () => this.onDeleteConfirm(id),
        onCancel() {},
      });
    },

    async onDeleteConfirm(id) {
      try {
        const res = await productService.deleteVideo(id);
        if (res.r) {
          await new Promise((resolve, reject) => {
            setTimeout(() => resolve(), 1000); // 항상 resolve를 호출하도록 변경
          });
          Modal.destroyAll(); // 모달 닫기
          await this.fetchVideoList(this.from, this.to);
        }
      } catch (error) {
        // 오류 처리
        console.error('Error during video deletion:', error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  margin: 0 auto;
  padding-top: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-contents {
    display: flex;
    width: 100%;
    gap: 32px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}

.rect {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #343a40;
  border-radius: 12px;

  label {
    color: #adb5bd !important;
  }
}

.btn-liveView {
  border-radius: 4px;
  background-color: rgba(248, 221, 74, 1);
}

.date-navigation {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #495057;
  border-radius: 20px;
}

.video-list {
  margin-top: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 600px;
  }

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 12px;
  }

  ul > li {
    width: 100%;
    height: 112px;
    background-color: #495057;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .video-summary {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    align-items: center;

    div + div {
      margin-left: 8px;
    }

    .preview {
      width: 100px;
      height: 60px;
      border-radius: 8px;
      object-fit: cover;
    }

    @media (min-width: 768px) {
      div + div {
        margin-left: 16px;
      }

      .preview {
        width: 142px;
        height: 82px;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }

  .video_tag {
    margin-left: 8px;
    width: 60px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    &.Front {
      color: #000;
      background: #F8DD4A;
      border: 1px solid #F8DD4A;;
    }
    &.Rear {
      color: #F8DD4A;
      background: none;
      border: 1px solid #F8DD4A;
    }
    
    >span {
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
      &.Front {
        color:#000;
      }
      &.Rear {
        color: #F8DD4A;
      }
    }
  }
}
</style>
