<template>
  <div class="main-container">
    <div class="top-contents">
      <div class="rect">
        <h2>Live View</h2>
        <p>Available Device List.</p>
        <p>
          If your device is not shown, check internet connection of your device.
        </p>
        <div class="video-list">
          <div
            v-if="deviceList.length === 0"
            :style="{
              display: 'flex',
              justifyContent: 'center',
              height: '80px',
            }"
          >
            <span>No Device Available</span>
          </div>
          <div v-else :style="{ width: '100%' }">
            <ul>
              <li v-for="device of deviceList" :key="device.device_serial">
                <div
                  class="video-summary"
                  style="position: relative; cursor: pointer"
                  @click="handleClickPlay(device)"
                >
                  <div
                    :style="{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }"
                  >

                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                      <div :style="{ display: 'flex', flexDirection: 'row', alignItems:'center'}">
                        <span style="font-size: 12px; color: #bbb">
                          Device Serial
                        </span>
                        <span style="font-size: 14px; marginLeft:14px">{{
                          device.device_serial
                        }}</span>
                      </div>
                      <div :style="{ display: 'flex', flexDirection: 'row', alignItems:'center'}">
                        <span style="font-size: 12px; color: #bbb">
                          Product Name
                        </span>
                        <span style="font-size: 14px; marginLeft:4px">{{
                          device.product_name
                        }}</span>
                      </div>
                      <div :style="{ display: 'flex', flexDirection: 'row', alignItems:'center'}">
                        <span style="font-size: 12px; color: #bbb">
                          Device Name 
                        </span>
                        <span style="font-size: 14px; marginLeft:4px">
                        {{
                          device.name
                        }}</span>
                      </div>
                    </div>


                    <div>
                      <span v-if="device.online" style="font-size: 14px; color:rgb(9, 186, 204)">Online</span>
                      <span v-if="!device.online" style="font-size: 14px; color:#bbb">Offline</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  PlayCircleFilled,
} from "@ant-design/icons-vue";
import productService from "../services/product";
import memberService from "../services/member";
import moment from "moment";

export default {
  components: { LeftOutlined, RightOutlined, DeleteOutlined, PlayCircleFilled },
  data() {
    return {
      storage: { disk: "0", usage: 0, download: 0, remote: 0, upload: 0 },
      deviceList: [],
    };
  },
  computed: {
    filteredVideoList() {
      return this.selectedDeviceSerial
        ? this.videoList.filter(
            (video) => video.device_serial === this.selectedDeviceSerial
          )
        : this.videoList;
    },
  },
  mounted() {
    const user = this.$store.state.user;
    this.fetchDeviceList();
  },
  methods: {
    async fetchDeviceList() {
      const res = await productService.fetchCurrentDeviceList();
      this.deviceList = res.list;
    },
    handleClickPlay(device) {
      if(device.online)
        this.$router.push(`/live/${device.device_serial}`);
      else 
        ;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  margin: 0 auto;
  padding-top: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-contents {
    display: flex;
    width: 100%;
    gap: 32px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
}

.rect {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #343a40;
  border-radius: 12px;

  label {
    color: #adb5bd !important;
  }
}

.btn-liveView {
  border-radius: 4px;
  background-color: rgba(248, 221, 74, 1);
}

.date-navigation {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #495057;
  border-radius: 20px;
}

.video-list {
  margin-top: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 600px;
  }

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 12px;
  }

  ul > li {
    width: 100%;
    height: 112px;
    background-color: #495057;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .video-summary {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px 24px;
    align-items: center;

  }
}
</style>
