import axios from './sticarAxios';

export default {
  fetchIsLogin(token) {
    return axios
      .get(`/member/islogin`, { params: { token } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  login(username, password) {
    return axios
      .post(`/member/login`, { email: username, pass: password })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
};
