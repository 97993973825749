<template>
  <div class="desktop-layout">
    <Header />
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.desktop-layout {
  width: 1400px;
  height: 100vh;
  margin: 0 auto;
  background-color: #1c2025;
}
</style>
