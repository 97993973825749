<template>
  <div class="live-view-container">
    <div class="player-wrapper">
      <div class='title' style="display: flex; justify-content: space-between">
        <span>Live View</span>
      </div>
      <div class="player">
        <video 
          class="remote-view" 
          ref="videoPlayer"
          style="width: 100%; height: 100%; border-radius: 8px"
          autoplay playsinline controls></video>
        <!-- <video
          ref="videoPlayer"
          class="video-js"
          data-setup='{"fluid": true}'
          style="width: 100%; height: 100%; border-radius: 8px"
          width="100%"
          height="100%"></video> -->
      </div>
      <div style="display: flex; justify-content: flex-end">
        <span>Speed {{}} <a-divider type="vertical" />GPS {{}}</span>
      </div>
    </div>
    <div class="map-wrapper">
      <google-map :path="path" :carPosition="carPosition" :style="{ borderRadius: '8px' }" />
    </div>
  </div>
</template>

<script>

import GoogleMap from '../components/GoogleMap.vue';
import productService from "../services/product";
import AWS from "aws-sdk";
const { SignalingClient } = require("amazon-kinesis-video-streams-webrtc");

export default {
  components: {
    GoogleMap,
  },
  data() {
    return {
      video: null,
      path: [],
      viewer: {},
      carPosition: {}
    };
  },
  methods : {
    async tick() {
      const {device_serial} = this.$route.params;
      await productService.fetchLiveContinue(device_serial)
      setTimeout(()=>{
        this.getCarPosition(device_serial);
        this.tick();

      }, 10000)
    },

    async getCarPosition(device_serial) {
      const res = await productService.fetchLastPosition(device_serial);
      if (!res.r) {
        return;
      }
      if(res && res.r && res.track_list && res.track_list.length > 0) {
        const position = res.track_list[0];
        this.carPosition = {
          lat: position.latitude,
          lng: position.longitude
        }
      }
    },
  },
  async mounted() {
    const {device_serial} = this.$route.params;
    let a = await productService.fetchLiveStart(device_serial);
    this.getCarPosition(device_serial);
    const remoteView = this.$refs.videoPlayer

    let {region, accessKeyId, secretAccessKey} = a
    let channelARN = a.arn;
    let endpointsByProtocol = a.channelInfo.endpointsByProtocol;
    let clientId = Math.random().toString(36).substring(2, 12);

    let viewer = {};
    let formValues = {
      useTrickleICE: true,
    }

    let iceServers = [];
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
    });
    a.channelInfo.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    );

    const kinesisVideoClient = new AWS.KinesisVideo({
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: null,
      endpoint: null,
      correctClockSkew: true,
    });

    // Create Signaling Client
    viewer.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId,
      role: "VIEWER",
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        sessionToken: null,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const configuration = {
      iceServers,
      iceTransportPolicy: "all",
    };
    viewer.peerConnection = new RTCPeerConnection(configuration);

    await viewer.peerConnection.setLocalDescription(
      await viewer.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
    );

    viewer.signalingClient.on("open", async () => {
      await viewer.peerConnection.setLocalDescription(
        await viewer.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      if (formValues.useTrickleICE) {
        viewer.signalingClient.sendSdpOffer(
          viewer.peerConnection.localDescription
        );
      }
    });

    viewer.signalingClient.on("sdpAnswer", async (answer) => {
      await viewer.peerConnection.setRemoteDescription(answer);
    });

    viewer.signalingClient.on("iceCandidate", (candidate) => {
      viewer.peerConnection.addIceCandidate(candidate);
    });

    viewer.signalingClient.on("close", () => {
    });

    viewer.signalingClient.on("error", (error) => {
    });

    viewer.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
      } else {
        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!formValues.useTrickleICE) {
          viewer.signalingClient.sendSdpOffer(
            viewer.peerConnection.localDescription
          );
        }
      }
    });

    viewer.peerConnection.addEventListener("track", (event) => {
      if (remoteView.srcObject) {
        return;
      }
      viewer.remoteStream = event.streams[0];
      remoteView.srcObject = viewer.remoteStream;

      //measure the time to first track
      if (formValues.enableDQPmetrics && connectionTime === 0) {
        initialDate = new Date();
        connectionTime = calcDiffTimestamp2Sec(
          initialDate.getTime(),
          viewerButtonPressed.getTime()
        );
      }


      const promise = remoteView.play();
      if(promise !== undefined){
          promise.then(() => {
              // Autoplay started
          }).catch(error => {
              // Autoplay was prevented.
              remoteView.muted = true;
              remoteView.play();
          });
      }
    });

    viewer.peerConnection.addEventListener(
      "connectionstatechange",
      async (event) => {
      }
    );

    viewer.signalingClient.open();

    this.tick()
  },
};
</script>

<style lang="scss" scoped>
.live-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 32px;
  margin-top: 162px;

  @media (max-width: 768px) {
    margin-top: 0px;
    flex-wrap: wrap;
  }

  .player-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 460px;

    .player {
      display: flex;
      /* margin: px 0px; */
      width: 100%;
      height: 100%;
    }
  }

  .map-wrapper {
    width: 100%;
    height: 404px;
  }
}
</style>
