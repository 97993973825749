<template>
  <div class="mobile-layout">
    <Header />
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.mobile-layout {
  width: 90%;
  margin: 0px auto;
  height: 100vh;
  height: -webkit-fill-available;
}
</style>
