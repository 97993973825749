<template>
  <section>
    <h1>Live Web RTC</h1>
    <div class="video-container">
      <video class="remote-view" autoplay playsinline controls></video>
    </div>
  </section>
</template>
  
<script>
import AWS from "aws-sdk";
const { SignalingClient } = require("amazon-kinesis-video-streams-webrtc");
import client from "../client/client";

export default {
  name: "Live Web RTC",
  data() {
    return {
      viewer: {},
    };
  },
  async mounted() {
    const remoteView = document.querySelector(".remote-view");
    //console.log('remoteView', remoteView)

    let a = await client.get("/api/webrtc/live/test/get/viewer");
    //console.log('a', a)

    let {region, accessKeyId, secretAccessKey} = a
    let channelARN = a.arn;
    let endpointsByProtocol = a.channelInfo.endpointsByProtocol;
    let clientId = Math.random().toString(36).substring(2, 12);

    let viewer = {};
    let formValues = {
      useTrickleICE: true,
    }

    let iceServers = [];
    iceServers.push({
      urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
    });
    a.channelInfo.IceServerList.forEach((iceServer) =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    );

    console.log("[VIEWER] ICE servers:", iceServers);
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: null,
      endpoint: null,
      correctClockSkew: true,
    });

    // Create Signaling Client
    viewer.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: clientId,
      role: "VIEWER",
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        sessionToken: null,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const configuration = {
      iceServers,
      iceTransportPolicy: "all",
    };
    viewer.peerConnection = new RTCPeerConnection(configuration);

    await viewer.peerConnection.setLocalDescription(
      await viewer.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
    );

    viewer.signalingClient.on("open", async () => {
      console.log("[VIEWER] Connected to signaling service");

      // Create an SDP offer to send to the master
      console.log("[VIEWER] Creating SDP offer");
      await viewer.peerConnection.setLocalDescription(
        await viewer.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (formValues.useTrickleICE) {
        console.log("[VIEWER] Sending SDP offer");
        console.debug("SDP offer:", viewer.peerConnection.localDescription);
        viewer.signalingClient.sendSdpOffer(
          viewer.peerConnection.localDescription
        );
      }
      console.log("[VIEWER] Generating ICE candidates");
    });

    viewer.signalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      console.log("[VIEWER] Received SDP answer");
      console.debug("SDP answer:", answer);
      await viewer.peerConnection.setRemoteDescription(answer);
    });

    viewer.signalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log("[VIEWER] Received ICE candidate");
      console.debug("ICE candidate", candidate);
      viewer.peerConnection.addIceCandidate(candidate);
    });

    viewer.signalingClient.on("close", () => {
      console.log("[VIEWER] Disconnected from signaling channel");
    });

    viewer.signalingClient.on("error", (error) => {
      console.error("[VIEWER] Signaling client error:", error);
    });

    viewer.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        console.log("[VIEWER] Generated ICE candidate");
        console.log("ICE candidate:", candidate);
      } else {
        console.log("[VIEWER] All ICE candidates have been generated");

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!formValues.useTrickleICE) {
          console.log("[VIEWER] Sending SDP offer");
          console.log("SDP offer:", peerConnection.localDescription);
          viewer.signalingClient.sendSdpOffer(
            viewer.peerConnection.localDescription
          );
        }
      }
    });

    viewer.peerConnection.addEventListener("track", (event) => {
      console.log("[VIEWER] Received remote track");
      if (remoteView.srcObject) {
        return;
      }
      viewer.remoteStream = event.streams[0];
      remoteView.srcObject = viewer.remoteStream;

      //measure the time to first track
      if (formValues.enableDQPmetrics && connectionTime === 0) {
        initialDate = new Date();
        connectionTime = calcDiffTimestamp2Sec(
          initialDate.getTime(),
          viewerButtonPressed.getTime()
        );
      }


      const promise = remoteView.play();
      if(promise !== undefined){
          promise.then(() => {
              // Autoplay started
          }).catch(error => {
              // Autoplay was prevented.
              remoteView.muted = true;
              remoteView.play();
          });
      }
    });

    viewer.peerConnection.addEventListener(
      "connectionstatechange",
      async (event) => {
        console.log("connectionstatechange", event);
      }
    );

    console.log("[VIEWER] Starting viewer connection");
    viewer.signalingClient.open();
  },
};
</script>
  
<style scoped>
</style>
  