import NotFound from './views/NotFound.vue';
import Login from './views/Login.vue';
import Home from './views/Home.vue';
import Main from './views/Main.vue';
import LiveView from './views/LiveView.vue';
import ReplayView from './views/ReplayView.vue';
import Geofence from './views/Geofence.vue';
import MapView from './views/MapView.vue';
import LiveMobileView from './views/LiveMobileView.vue';
import LiveTest from  './views/LiveTest.vue';
import DeviceSelectView from './views/DeviceSelectView.vue';

import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

const beforeEnterGuard = async (to, from) => {
  if (!store.state.isLoggedIn) {
    const isAuthorized = await store.dispatch('isAuthorized');
    if (!isAuthorized) return { path: '/login' };
  }
};

const routes = [
  {
    path: '/',
    component: Home,
    beforeEnter: async (to, from) => {
      if (localStorage.getItem('isLoggedIn')) {
        await store.dispatch('isAuthorized');
      }
    },
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/main',
    component: Main,
    beforeEnter: beforeEnterGuard,
  },
  {
    path: '/replay/:id',
    component: ReplayView,
    beforeEnter: beforeEnterGuard,
  },
  {
    path: '/live/:device_serial',
    component: LiveView,
    beforeEnter: beforeEnterGuard,
  },
  {
    path: '/device/select',
    component: DeviceSelectView,
    beforeEnter: beforeEnterGuard,
  },
  {
    path: '/livemobile',
    component: LiveMobileView,
    meta: { layout: 'map' },
  },
  {
    path: '/geofence',
    component: Geofence,
    meta: { layout: 'map' },
  },
  {
    path: '/map',
    component: MapView,
    meta: { layout: 'map' },
  },
  {
    path: '/livetest',
    component: LiveTest,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
