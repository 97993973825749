import axios from './clientAxios';

export default {
  updateToken(query) {
    if (query && query.token) {
      localStorage.setItem('token', query.token);
    }
  },
  get(api) {
    const token = localStorage.getItem('token');
    return axios
      .get(api, { headers: { 'x-access-token': token } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  post(api, data) {
    const token = localStorage.getItem('token');
    return axios
      .post(api, data, { headers: { 'x-access-token': token } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
};
