<template>
  <div class="header-container" :hidden="currentPath.includes('login')">
    <img src="../assets/img/logo.png" alt="logo" @click="handleClickLogo" :style="{ width:'149px', cursor: 'pointer' }" />
    <div>
      <button v-if="isLoggedIn && currentPath.includes('main')" class="btn ghost" @click="handleClickLogout">Logout</button>
      <button v-else-if="isLoggedIn" class="btn ghost" @click="handleClickMyInfo">My Info</button>
      <button v-else class="btn ghost" @click="handleClickLogin">Login</button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentPath() {
      return this.$route.path;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    handleClickLogo() {
      this.$router.push('/');
    },
    handleClickLogin() {
      this.$router.push('/login');
    },
    handleClickLogout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    handleClickMyInfo() {
      this.$router.push('/main');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn.ghost {
  width: 136px;
  height: 40px;
  color: #F8DD4A;
  border: 1px solid #F8DD4A;
  border-radius: 4px;
  text-align: center;
}
</style>
