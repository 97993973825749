<template>
  <section>
    <h1>Page not found</h1>
  </section>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped></style>
