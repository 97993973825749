import axios from 'axios';
const baseUrl = process.env.VUE_APP_API_BASE_URL;

axios.defaults.withCredentials = true;
export const instanceWithCredential = axios.create({
  baseURL: baseUrl,
  timeout: 180000,
  withCredentials: false,
});

export default instanceWithCredential;
