<template>
    <div class="map-layout">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .map-layout {
    width:100%;
    height: 100%;
    height: -webkit-fill-available;
  }
  </style>