<template>
<div class="map-container">
    <google-map :path="path" :carPosition="carPosition" :style="{ position: 'absolute' }" />
</div>
</template>

<script>
import client from "../client/client";
import { useRoute } from 'vue-router';
import GoogleMap from '../components/GoogleMap.vue';
import { isEmpty } from 'lodash';
import productService from "../services/product";

//test url
// /livemobile?device_serial=90349301240027&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJfbm8iOiIxNjg3NDk5MTY3OTI4IiwiZW1haWwiOiJ0ZXN0MkBnbWFpbC5jb20iLCJuYW1lIjoidGVzdDIiLCJ0eXBlIjoiZW1haWwiLCJpYXQiOjE3MDgwNDU4MTAsImV4cCI6MTc5NDQ0NTgxMCwiaXNzIjoiLmVjaG9tYXN0ZXJvcHNpcy5jb20iLCJzdWIiOiJ1c2VySW5mbyJ9.np7Igu1TobSk0dDXboHL7g9YK2pQ_Z33RzOkjB7u9ys

export default {
    name: 'LiveMobileView',
components: {
    GoogleMap,
},
data() {
    return {
      path: [],
      pin: '',
      device_serial:null,
      carPosition: {}
    };
},
created() {
},
mounted() {
  const { query } = useRoute();
  if(query.device_serial)
    this.device_serial = query.device_serial;
  if (isEmpty(query)) return;
  client.updateToken(query);
  if(this.device_serial) {
    this.getCarPosition(this.device_serial);
    this.tick();
  }
},
methods: {
  async tick() {
    setTimeout(()=>{
      this.getCarPosition(this.device_serial);
      this.tick();

    }, 10000)
  },

  async getCarPosition(device_serial) {
    const res = await productService.fetchLastPosition(device_serial);
    if (!res.r) {
      return;
    }
    if(res && res.r && res.track_list && res.track_list.length > 0) {
      const position = res.track_list[0];
      this.carPosition = {
        lat: position.latitude,
        lng: position.longitude
      }
    }
  },
},
};
</script>

<style lang="scss" scoped>
.map-container {
width: 100%;
height: 100%;
}
</style>
