<template>
  <div class="main-container">
    <div class="main-image">
      <img class="pc_main_image" src="../assets/img/main_image.png" alt="main_image" />
      <img class="t_main_image" src="../assets/img/t_main_image.png" alt="main_image" />
      <img class="m_main_image" src="../assets/img/mobile_img.png" alt="main_image" />
    </div>
    

    <div class="download">
      <div class="pc">
        <h2 :style="{ fontSize: '30px' }">PC Viewer</h2>
        <p :style="{ textAlign: 'center' }">
          PC software to view settings and videos from the SD card of<br />
          OPSIS by Stinger device.
        </p>
        <div :style="{ display: 'flex', flexDirection: 'column', marginTop: '12px', alignItems: 'center' }">
          <button class="btn-download" style="display: flex; align-items: center; justify-content: center"
            @click="download('https://www.opsisbystinger.com/opsisbystinger_release.zip')"
          >
            <img src="../assets/img/windows.png" alt="windows" style="margin-right: 8px" />Download for &nbsp;<b>Windows</b>
          </button>
          <button class="btn-download" style="margin-top: 20px; display: flex; align-items: center; justify-content: center"
            @click="download('https://s3.us-west-1.amazonaws.com/www.echomasteropsis.com/opsis_mac.pkg')"
          >
            <img src="../assets/img/mac.png" alt="mac" style="margin-right: 8px" />Download for &nbsp;<b>MAC</b>
          </button>
        </div>
      </div>
      <div class="app">
        <h2 style="font-size: 30px">APP Download</h2>
        <div style="display: flex; width: 100%; justify-content: space-evenly; margin-top: 48px">
          <div style="display: flex; flex-direction: column; align-items: center">
            <p style="text-align: center;">APP<br />Store</p>
            <div style="margin-top: 8px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 12px">
              <a href="https://itunes.apple.com/app/id6449980489?mt=8" target="_blank">
                <img src="../assets/img/appstore-badge.png" alt="app store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.aampglobal.dashcamapp" target="_blank">
                <img src="../assets/img/googleplay-badge.png" alt="google play" />
              </a>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; align-items: center">
            <p style="text-align: center;">QR<br />code</p>
            <img class="qrcode" src="../assets/img/qrcode.png" alt="app store" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    download (url) {
      window.location.href = url;
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.qrcode {
  width: 120px;
  height: 120px;
  border-radius: 8px;
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: spac;
  align-items: center;
}

.main-image {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.pc_main_image {
  display: block;
}

.t_main_image {
  display: none;
}

.m_main_image {
  display: none;
}

@media (max-width: 768px) {
  .pc_main_image {
    display: none;
  }
  
  .t_main_image {
    display: block;
  }
}

@media (max-width: 450px) {
  .t_main_image {
    display: none;
  }
  
  .m_main_image {
    display: block;
  }
}

.mobile-main-image {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: none;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.download {
  margin-top: 36px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .pc,
  .app {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #31373e;
  }
}

.btn-download {
  width: 300px;
  height: 72px;
  border-radius: 36px;
  background-color: rgba(248, 221, 74, 1);
  text-align: center;
  color:#000;
}
</style>
